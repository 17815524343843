<template>
  <div class="container">
    <h1>HEDGEHOG CLASSIFIER</h1>
    <v-toolbar v-show="state.isModelReady" dense>
    <v-btn @click.native="onPickFile()" icon><v-icon>mdi-file-upload</v-icon></v-btn>
    <v-btn @click.native="onCameraSelect()" icon><v-icon>mdi-camera</v-icon></v-btn>
    <v-btn :disabled="processedImageURL == null" @click.native="saveFileAndClass()" icon><v-icon>mdi-file-download</v-icon></v-btn>
    <v-spacer></v-spacer>
    <v-btn @click.native="state.mode ='help'; stopCamera(); cleanUp();" icon><v-icon>mdi-help</v-icon></v-btn>
    <input type="file" style="display: none" ref="fileInput" accept=".png, .jpg" @change="onFilePicked"/>
   </v-toolbar>
   <v-container style="text-align: center; margin-top: 20px;">
     
    <div v-if="!state.isModelReady">
    <br /><br />
    <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
    <br />Loading classifier...
    </div>
    <div v-else> <!-- state.isModelReady == true -->
      <div v-show="state.mode=='help'" style="text-align: left">
        Click the upload button <v-icon>mdi-file-upload</v-icon> to classify a local image, or the camera button <v-icon>mdi-camera</v-icon> to snap a photo.<br />
        <br />
        When in camera mode, click/press the image to snap a photo, click the image again to start the camera anew.<br />
        <br />
        HEDGEHOG CLASSIFIER, app version {{ version }}. Model version {{ modelVersion }}.
      </div>
      <div class="image-container" v-show="state.mode=='upload'">
        <div>Click the upload button to load an image or the camera button to snap a photo</div>
        <img class="preview-image" v-if="processedImageURL" :src="processedImageURL" />
      </div>

      <div class="image-container" v-show="state.mode=='camera'">
        <div v-if="!state.camera.cameraFailed">
          <div>Click/press the image to snap a photo, click the image again to start the camera anew</div>
          <video @click="takePhoto()" v-show="!state.camera.isPhotoTaken" id="video" ref="camera" :width="imageSize" :height="imageSize" autoplay></video>
          <img @click="releasePhoto()" :width="imageSize" :height="imageSize" v-show="processedImageURL&&state.camera.isPhotoTaken" :src="processedImageURL" />
        </div>
        <div v-else>
        Could not access camera. Either camera does not exist or camera access is not permitted.
        </div>
      </div>
      
      <div v-show="state.hasClassified && (state.mode=='upload' || (state.mode=='camera' && !state.camera.cameraFailed))" color="teal" class="image_to_classify">
          <span v-if="classifications.length > 0">I think this might be:<br /></span>
          <span v-for="(classification, index) in classifications" :key="index" class="classification-res">{{ hedgehogNameMap[classification.idx] }} ({{ (classification.probability*100).toFixed(1) }}%)<br /></span>
        </div>
    </div>

    <div v-show="state.isClassifying"><br /><v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular><br />Classifying</div>
     <div v-if="debug"><div>{{state.camera.width}}x{{state.camera.height}}</div>
     {{ debugLog }}
     </div> 
     
    </v-container>
  </div>
</template>

<script>
import * as tf from '@tensorflow/tfjs'
import { loadGraphModel } from '@tensorflow/tfjs-converter'
// import { math } from '@tensorflow/tfjs';

export default {
  name: 'HedgehogClassifier',
  data: () => ({
    version: "0.0.4",
    modelVersion: "2022-03-20",
    debug: false,
    debugLog: "",
    model_name: 'hntfjs-2022-03-20-no-quant/model.json',
    model: null,

    imageSize: 224, // 224 with mobilenet, the default I use is 256
    image: null,
    imageURL: null,
    processedImage: null,
    processedImageURL: null,
    pixelImage: null,
    hedgehogs: ["batman","blouf","cuddles","fluffy","hepyli","hepylito","lillis","pushkin","pytte","roundie","snuffe","toffe","albert","harry_otter","huskie","jay","miii","molle","michael","sidney","not_a_hedgehog"], // 21
    hedgehogNameMap: ["Batman","Blouf","Cuddles","Fluffy","Hepyli","Hepylito","Lillis","Pushkin","Pytte","Roundie","Snuffe","Toffe","Albert","Harry Otter","Huskie","Jay","Miii","Molle","Michael","Sidney","Not a Hedgehog"], // 21
    classifications: [],
    config: {
      probTreshold: 0.12, // minimal probability to suggest
      maxNumber: 3, // max number of items to show
      videoInterval: 1000, // in ms
    },
    state: {
      isModelReady: false,
      isCameraReady: false,
      isClassifying: false,
      hasClassified: false,
      mode: 'help', // upload, camera, help
      showHelp: false,

      camera: {
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        cameraFailed: false,
        width: 0,
        height: 0,
      }
    }
  }),
  mounted: function() {
    this.loadCustomModel();
  },
  methods: {
    loadCustomModel: function () {
      this.state.isModelReady = false;
      // load the model with loadGraphModel
      return loadGraphModel("/assets/models/"+this.model_name)
        .then((model) => {
          this.model = model;
          this.warmup().then(() => {
            this.state.isModelReady = true;
            if (this.debug) this.debugLog += "model loaded, ";
            console.log('model loaded: ', model);
          });
          
        })
        .catch((error) => {
          console.log('failed to load the model', error);
          if (this.debug) this.debugLog += "model load error, " + error;
          throw (error);
        })
    },
    warmup: async function() {
      let fakeData = tf.zeros([1,this.imageSize,this.imageSize,3]);
      let predictionsTensor = this.model.predict(fakeData);
      predictionsTensor.dispose();
      fakeData.dispose();
    },
    cleanUp: function() { // cleaning when switching modes
      this.pixelImage = null; //
      this.processedImageURL = null;
    },
    onPickFile: function() {
        this.cleanUp();
        this.$refs.fileInput.click();
      },
    onFilePicked: function(event) {
      this.state.mode= 'upload';
      this.stopCamera();
        const image_file = event.target.files[0];
        //let filename = files[0].name;
        const fileReader = new FileReader();
        let that = this;
        fileReader.addEventListener('load', () => {
          this.image = new Image();
          this.imageURL = URL.createObjectURL(image_file);
          this.image.src = this.imageURL;
          this.image.onload = function(e) {
            if (this.debug) console.log(e);
            that.state.isClassifying = true;
            that.classifications = [];
            that.processAndClassifyImage(that.image);
          }
        });
        if (image_file)
          fileReader.readAsDataURL(image_file);
     },
     saveFileAndClass: function() {
        if (this.state.hasClassified == false || this.processedImageURL == null) return;

        const link = document.createElement('a');
        let classification = "";
        let uuid = self.crypto.randomUUID();
        if (this.classifications.length == 0) classification = "unknown";
        else {
         classification = this.classifications[0].class; // grab the first identified name
        }

        link.setAttribute("download", classification + "-"+ uuid + ".png");
        link.setAttribute("href", this.processedImageURL);

        console.log("here we go");
        link.click();
     },
     onCameraSelect: function() {
      this.cleanUp();
      if (this.state.mode != 'camera') {
        this.state.mode='camera';
        if (!this.state.camera.cameraFailed) // if failed, force refresh before trying again.
          this.toggleCamera();
      }
    },
    stopCamera: function() {
      if (this.state.camera.isCameraOpen) this.stopCameraStream();
        this.state.camera.isCameraOpen = false;
        this.state.camera.isPhotoTaken = false;
        this.state.camera.isShotPhoto = false;
        
    },
    toggleCamera: function() {
      if(this.state.camera.isCameraOpen) {
        this.state.camera.isCameraOpen = false;
        this.state.camera.isPhotoTaken = false;
        this.state.camera.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.state.camera.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement: function() {
      this.isLoading = true;
      const constraints = (window.constraints = {
				audio: false,
				video: {
          facingMode: 'environment'
        }
			});
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.state.camera.isLoading = false;
					this.$refs.camera.srcObject = stream;
          let stream_settings = stream.getVideoTracks()[0].getSettings();
           this.state.camera.width = stream_settings.width;
          this.state.camera.height = stream_settings.height;
          this.continualClassification();
				})
				.catch(error => {
          this.state.camera.isLoading = false;
          this.state.camera.isCameraOpen = false;
          this.state.camera.cameraFailed = true;
					if (this.debug) this.debugLog += "camera error, " + error;
				});
    },
    stopCameraStream: function() {
      let tracks = this.$refs.camera.srcObject.getTracks();
			tracks.forEach(track => {
				track.stop();
			});
    },
    releasePhoto: function() {
      this.state.camera.isPhotoTaken = false;
      this.classifications = [];
      this.continualClassification();
    },
    continualClassification: function() {
      if (this.state.camera.isPhotoTaken||this.state.mode!="camera") return; // photo active, no video stream visible

      let imageURL = this.videoStreamAnalyse();
      let image = new Image();
      image.src = imageURL;
      let that = this;
      image.onload = function() {
        that.state.isClassifying = true;
        that.processAndClassifyImage(image);

        setTimeout(() => {
          that.continualClassification();
        }, that.config.videoInterval);
      }
    },
    videoStreamAnalyse: function() {
      const videoEl = document.getElementById("video");
      const canvas = document.createElement("canvas");
      canvas.width = this.state.camera.width;
      canvas.height = this.state.camera.height;
      canvas.getContext('2d').drawImage(videoEl, 0, 0, canvas.width, canvas.height);

      return canvas.toDataURL();
    },
    takePhoto: function() {
      if(!this.state.camera.isPhotoTaken) {
        this.state.camera.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.state.camera.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      let imageURL = this.videoStreamAnalyse();
      let image = new Image();
      image.src = imageURL;
      let that = this;
      image.onload = function() {
        that.state.camera.isPhotoTaken = true;
        that.state.isClassifying = true;
        that.processAndClassifyImage(image);
      }
    },
    processAndClassifyImage: function(image) {
      if (this.debug) this.debugLog += "processAndClassify, ";
      // processedImageURL not stricltly needed, it's for debugging the cropping
      // pixelImage is needed because ImageData is needed as input to tfjs
      let that = this;
      this.state.hasClassified = false;
      
      this.cropandResizeImage(image, this.imageSize).then((results) => {
        if (that.debug) that.debugLog += "cropAndResizeReturn, ";
        that.processedImageURL = results[1];
        that.pixelImage = results[0];
        that.processedImage = new Image();
        that.processedImage.src = that.processedImageURL;
        //console.log(that.pixelImage);

        that.classifyImage(that.pixelImage).then((predictions) => {
          if (that.debug) that.debugLog += "classify return, ";
          try {
            if (that.debug) that.debugLog += "predictions: " + predictions.length + ", ";
          } catch (error) {
              if (that.debug) that.debugLog += error;
          }
          that.classifications = [];
          for (let i = 0; i < predictions.length; i++) {
            if (predictions[i]>that.config.probTreshold) // everything with a probability higher than the threshold
              that.classifications.push({idx: i, class: that.hedgehogs[i], probability:predictions[i]});
            
          }
          that.classifications.sort((a, b) => a.probability < b.probability && 1 || -1);

          if (that.classifications.length>that.config.maxNumber) that.classifications = that.classifications.slice(0, that.config.maxNumber);
          that.state.hasClassified = true;
          that.state.isClassifying = false;
          //console.log(that.classifications.length);
          if (that.debug) that.debugLog += that.classifications + ",";
        });
      });

    },

    cropandResizeImage: async function(img, imageSize) {
      let width = img.width;
      let height = img.height;
      let minSize = (width<height)? width: height;
      let diff_w = width - minSize;
      let diff_h = height - minSize;
      let x1 = Math.floor(diff_w/2);
      let y1 = Math.floor(diff_h/2);

      //console.log(typeof img + " " + x1 + " " + y1 + " " + diff_w + " " + diff_h);

      var canvas_cropper =  document.createElement("canvas"); 
      canvas_cropper.width = imageSize;
      canvas_cropper.height = imageSize; 
      canvas_cropper.getContext("2d").drawImage(img, x1, y1, minSize, minSize, 0, 0, imageSize, imageSize);

      return [canvas_cropper.getContext("2d").getImageData(0,0,imageSize,imageSize), canvas_cropper.toDataURL()];

    },
    classifyImage: async function (image) {
      if (!this.state.isModelReady) {
        if (this.debug) this.debugLog += "model is still loading, ";
        return;
      }
      const tfImg = tf.browser.fromPixels(image);
      //const smallImg = tf.image.resizeBilinear(tfImg, [this.image_size, this.image_size]) // 600, 450 // not needed as it's resized already
      const imageTensor = tf.cast(tfImg, 'float32'); // float16 was used in quantification
      
      let t4d = tf.tensor4d(Array.from(imageTensor.dataSync()),[1,this.imageSize,this.imageSize,3]); // reshape to right input format

      // rescale pixel values to -1...1 which is the expected input for mobilenet
      t4d = t4d.mul(tf.scalar(2/255)); 
      t4d = t4d.sub(tf.scalar(1)); 

      //console.log(t4d.dataSync());
      let predictionsTensor = this.model.predict(t4d);
      let predictions = predictionsTensor.arraySync();
      predictionsTensor.dispose();
      imageTensor.dispose();
      tfImg.dispose(); 
      t4d.dispose();
      return predictions[0];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  font-size: 60px;
  text-align: center;
}

.container {
  max-width: 800px;
}

.image-container {
  width: 100%;
  text-align: center
}
.preview-image {
  max-width: 80%;
  max-height: 300px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

@media only screen and (max-width: 1023px) {
  h1 {
    font-size: 28px;
    text-align: center;
  }
}
</style>
