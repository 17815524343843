<template>
  <v-app>
    <v-main>
      <HedgehogClassifier />
    </v-main>
  </v-app>
</template>

<script>
import HedgehogClassifier from './components/HedgehogClassifier';

export default {
  name: 'App',

  components: {
    HedgehogClassifier,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
 html {
  overflow: hidden !important;
  height: 100%;
}

body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>